export const isDev: boolean = process.env.NODE_ENV === 'development';

export const randomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

export const getRandomElement = (arr: any[]) => (arr.length ? arr[Math.floor(Math.random() * arr.length)] : undefined);

// C# Ticks -------------------------------------
export function JSDateTocsDatetimeTick(jsd = new Date()): number {
  return 621355968000000000 + jsd.getTime() * 10000;
}

export function csDatetimeTickToJSDate(csDatetimeTick: number): Date {
  const ticksToMicrotime = csDatetimeTick / 10000;
  const epochMicrotimeDiff = 621355968000000000 / 10000; // 1/1/1970

  return new Date(ticksToMicrotime - epochMicrotimeDiff);
}

// URL handling helpers -------------------------
export function urlFriendly(text: string): string {
  const trimmed = text.trim().toLowerCase();

  const noSpaces = trimmed.replace(/\s+/g, '_');
  const safeChars = noSpaces.replace(/[^a-z0-9_-]/g, '');

  return safeChars;
}

export function splitStringIntoChunksRegex(str: string, chunkSize: number): string[] {
  const regex = new RegExp(`.{1,${chunkSize}}`, 'g');
  return str.match(regex) || [];
}
