'use client';

import { useEffect } from 'react';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { firebaseApp } from '@/app/lib/Firebase/Firebase';

declare global {
  interface Window {
    FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string;
  }
}

const SDFirebaseAppCheck = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    // if (process.env.NODE_ENV === "development") {
    //   self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
    // }

    if (process.env.NODE_ENV === 'production') {
      const appCheck = initializeAppCheck(firebaseApp, {
        provider: new ReCaptchaV3Provider(process.env.NEXT_PUBLIC_FIREBASE_APPCHECK_V3 ?? ''), // ID: "6LdHcHkpAAAAAJQiCLeE_VplGJRJasSNROLZ_mlF"
        isTokenAutoRefreshEnabled: true,
      });
    }
  }, []);

  return <>{children}</>;
};
export default SDFirebaseAppCheck;
