import { csDatetimeTickToJSDate, splitStringIntoChunksRegex } from './utils';
import { deleteCookie, getCookie, hasCookie, setCookie } from 'cookies-next';

export const STORAGE_KEY = {
  accountId: process.env.NODE_ENV === 'development' ? 'accountId' : 'aid',
  businessName: process.env.NODE_ENV === 'development' ? 'businessName' : 'bn',
  applicationId: process.env.NODE_ENV === 'development' ? 'applicationId' : 'pid',
  // JWT
  refreshRTIntervalID: process.env.NODE_ENV === 'development' ? 'RTIntervalID' : 'ivid',
};

//#region Cookie JWT related
export function isAccessTokenExpired(ate?: string): boolean {
  const accessExp = parseInt(ate ?? getATExp() ?? '');
  return accessExp ? csDatetimeTickToJSDate(accessExp) < new Date() : true;
}

export function isRefreshTokenExpired(rte?: string): boolean {
  const expNum = parseInt(rte ?? getRTExp() ?? '');
  return expNum ? csDatetimeTickToJSDate(expNum) < new Date() : true;
}

export function isLoggedIn(): boolean {
  // return getStorageByKey(STORAGE_KEY.accessToken) != null
  // console.log("isLoggedIn()", getCookie("ui"), !isAccessTokenExpired())
  return hasCookie('ui') && !isAccessTokenExpired();
}

export const getUserIDFromCookie = () => getCookie('ui');
export const getATExp = () => getCookie('ae');
export const getRTExp = () => getCookie('re');

export const setAutoJWTRefreshIntervalID = (intervalID: number | NodeJS.Timeout) =>
  typeof window !== 'undefined' && localStorage.setItem(STORAGE_KEY.refreshRTIntervalID, intervalID.toString());

export const getAutoJWTRefreshIntervalID = (): number | null => {
  if (typeof window !== 'undefined') {
    const ivid = localStorage.getItem(STORAGE_KEY.refreshRTIntervalID);
    if (ivid) {
      const r = parseInt(ivid);
      return Number.isNaN(r) ? null : (r as number);
    }
  }
  return null;
};

export const removeAutoJWTRefreshIntervalID = () =>
  typeof window !== 'undefined' && localStorage.removeItem(STORAGE_KEY.refreshRTIntervalID);
//#endregion

//#region Traditional JWT in localStorage
export interface JWTToken {
  accessToken: string;
  refreshToken: string;
  accessTokenExpires: number;
  refreshTokenExpires: number;
  userId: string;
}
export enum JWTStorageKey {
  ACCESS_TOKEN = 'at',
  REFRESH_TOKEN = 'r',
  ACCESS_EXPIRY = 'ae',
  REFRESH_EXPIRY = 're',
  USER_ID = 'ui',
}
``;

export const setUserToken = (jwtToken: JWTToken): boolean => {
  try {
    if (jwtToken.accessToken === undefined) {
      return false;
    }
  } catch (err) {
    return false;
  }

  removeStoredUserToken();

  const chunkedAT = splitStringIntoChunksRegex(jwtToken.accessToken, 1299);
  // console.log(chunkedAT)

  const host = process.env.NODE_ENV === 'development' ? {} : { domain: window?.location.host };
  // console.log("Cookie host", host)

  let maxAgeSec = process.env.NODE_ENV === 'development' ? 2 * 60 : 30 * 60;

  setCookie(JWTStorageKey.USER_ID, jwtToken.userId, { secure: true, sameSite: 'strict', ...host });
  setCookie(JWTStorageKey.ACCESS_EXPIRY, jwtToken.accessTokenExpires, { secure: true, sameSite: 'strict', ...host });
  setCookie(JWTStorageKey.REFRESH_TOKEN, jwtToken.refreshToken, { secure: true, sameSite: 'strict', ...host });
  setCookie(JWTStorageKey.REFRESH_EXPIRY, jwtToken.refreshTokenExpires, { secure: true, sameSite: 'strict', ...host });
  setCookie('a', chunkedAT[0], { secure: true, sameSite: 'strict', ...host, maxAge: maxAgeSec });
  setCookie('b', chunkedAT[1], { secure: true, sameSite: 'strict', ...host, maxAge: maxAgeSec });
  setCookie('c', chunkedAT[2], { secure: true, sameSite: 'strict', ...host, maxAge: maxAgeSec });
  setCookie('d', chunkedAT[3], { secure: true, sameSite: 'strict', ...host, maxAge: maxAgeSec });
  setCookie('e', chunkedAT[4], { secure: true, sameSite: 'strict', ...host, maxAge: maxAgeSec });
  setCookie('f', `${chunkedAT[5] ?? ''}${chunkedAT[6] ?? ''}${chunkedAT[7] ?? ''}`, {
    secure: true,
    sameSite: 'strict',
    ...host,
    maxAge: maxAgeSec,
  });

  return true;
};

export const removeStoredUserToken = () => {
  const host = process.env.NODE_ENV === 'development' ? {} : { domain: window?.location.host };

  [
    JWTStorageKey.USER_ID,
    JWTStorageKey.ACCESS_EXPIRY,
    JWTStorageKey.REFRESH_TOKEN,
    JWTStorageKey.REFRESH_EXPIRY,
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
  ].forEach((k) => deleteCookie(k, { secure: true, sameSite: 'strict', ...host }));
};

export const getBearToken = (withBearerPrefix: boolean = false): string | null => {
  let a = getCookie('a') ?? '';
  let b = getCookie('b') ?? '';
  let c = getCookie('c') ?? '';
  let d = getCookie('d') ?? '';
  let e = getCookie('e') ?? '';
  let f = getCookie('f') ?? '';

  const token = [a, b, c, d, e, f].join('');

  return token.length === 0 ? null : withBearerPrefix ? `Bearer ${token}` : token;
};

export const getStoredTokenBodyForRefresh = () => {
  let accessToken = getBearToken(false);
  let refreshToken = getCookie(JWTStorageKey.REFRESH_TOKEN);

  return accessToken != null && refreshToken != null ? { accessToken, refreshToken } : null;
};
//#endregion

//#region LocalStorage Utils
/**
 * Local Storage Utilities Get function
 * @param {string} key
 * @param {*} defaultVal
 * @returns return value stored
 */
export const getStoredValue = (
  key: string,
  defaultVal: string | null = null,
  saveIfMissing: boolean = false,
  toLocalStorage: boolean = true
): string | null => {
  try {
    let sVal = toLocalStorage ? window.localStorage.getItem(key) : window.sessionStorage.getItem(key);

    if (sVal && sVal.length > 0) {
      try {
        sVal = JSON.parse(sVal);
      } catch (e) {}

      return sVal;
    }

    if (saveIfMissing) {
      setStoreValue(key, defaultVal ?? '', toLocalStorage);
    }
  } catch (error) {
    process.env.NODE_ENV === 'development' && console.error(error);
    return defaultVal;
  }

  return defaultVal;
};

/**
 * Store giving value into browser local storage
 * @param {string} key
 * @param {string/JSON} val
 */
export const setStoreValue = (key: string, val: string, toLocalStorage: boolean = true) => {
  if (typeof window === 'undefined') throw new Error("Local Storage isn't available in server-rendering environments");

  try {
    let targetStorage = toLocalStorage ? window.localStorage : window.sessionStorage;
    targetStorage.setItem(key, val);
  } catch (error) {
    throw error;
  }
};

export const removeStorage = (key: string, toLocalStorage: boolean = true) => {
  if (typeof window === 'undefined') throw new Error("Local Storage isn't available in server-rendering environments");

  try {
    toLocalStorage ? window.localStorage.removeItem(key) : window.sessionStorage.removeItem(key);
  } catch (error) {
    throw error;
  }
};
//#endregion
