// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Firebase ~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
import { initializeApp, getApps } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
// Tips: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-librariess
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, signInAnonymously } from 'firebase/auth';

// Mission Control's Firebase configuration. For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_APIKEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_REALTIMEDB_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECTID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENTID,
};
export const firebaseApp = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
// const analytics              = getAnalytics(firebaseApp)
export const firebaseDB = getDatabase(firebaseApp);
export const fireFirestoreDB = getFirestore(firebaseApp);

// Anonymouse Signin
const auth = getAuth();
signInAnonymously(auth)
  .then(() => {})
  .catch((error) => {
    const errCode = error.code;
    const errMsg = error.message;
    console.error('Firebase auth error:', errCode, errMsg);
  });

// Emulator
if (process.env.NEXT_USE_FIREBASE_EMULATOR === 'true' && process.env.NODE_ENV === 'development') {
  connectDatabaseEmulator(
    firebaseDB,
    process.env.NEXT_PUBLIC_EMULATOR_ADDRESS ?? '127.0.0.1',
    Number(process.env.NEXT_PUBLIC_EMULATOR_REALTIMEDB_PORT ?? 9000)
  );
  connectFirestoreEmulator(
    fireFirestoreDB,
    process.env.NEXT_PUBLIC_EMULATOR_ADDRESS ?? '127.0.0.1',
    Number(process.env.NEXT_PUBLIC_EMULATOR_FIRESTORE_PORT ?? 8080)
  );
}
