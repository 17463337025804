'use client';
import { useEffect, useState } from 'react';
import Image from 'next/image';
import { isLoggedIn } from '@/app/lib/storageCommon';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, useDisclosure } from '@nextui-org/react';
import logoutSVG from '@/app/components/assets/logout.svg';
import { useRouter } from 'next/navigation';
import { SITE_MAP } from '../api/api';

export default function SDHeader() {
  const { push } = useRouter();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  useEffect(() => {
    setLoggedIn(isLoggedIn());
  }, []);

  return (
    <>
      <header className='bg-black shadow-lg'>
        <nav aria-label='Global' className={`mx-auto flex shrink-0 grow items-center justify-between p-4 md:gap-x-12`}>
          {/* Logo */}
          <div className={`flex md:flex-none`}>
            <a href='/' className='-m-1.5 p-1.5'>
              <span className='sr-only'>Mission Control | Seamless Digital Ltd.</span>
              <Image src='/logo/brand-w.svg' width={119.3} height={40} alt='Logo' />
            </a>
          </div>

          {/* Logout Button */}
          {loggedIn && (
            <Button isIconOnly size='sm' aria-label='Logout' className='bg-transparent' onClick={onOpen}>
              <Image src={logoutSVG} width={22} height={22} alt='Logout' />
            </Button>
          )}

          <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement='center'>
            <ModalContent>
              {(onClose) => (
                <>
                  <ModalBody className='mt-5'>Confirm to logout?</ModalBody>
                  <ModalFooter className='items-bottom flex flex-row-reverse justify-between gap-3'>
                    <Button color='primary' radius='sm' onClick={() => push(SITE_MAP.LOGOUT)}>
                      Logout
                    </Button>
                    <Button color='secondary' variant='light' radius='sm' onClick={onClose}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </Modal>
        </nav>
      </header>
    </>
  );
}
